import React, { Fragment } from 'react';
import { ActionTableBodyItem, TableBodyItem, TableItemImage } from '../../Table.styles';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/chevron_right.svg';
import { formatPrice } from '../../../../utils/util';

const InfluencerItem = ({ name, profilePhoto, totalSubscriptionEarnings, totalContentEarnings, totalEarnings }) => {
  return (
    <Fragment>
      <TableBodyItem>
        <TableItemImage src={profilePhoto} alt="influencer-profile" />
        <p>{name || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>${formatPrice(totalSubscriptionEarnings)}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>${formatPrice(totalContentEarnings)}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>${formatPrice(totalEarnings)}</p>
      </TableBodyItem>
      <ActionTableBodyItem>
        <ArrowIcon width={24} height={24} color="#1672BA" />
      </ActionTableBodyItem>
    </Fragment>
  );
};

export default InfluencerItem;
