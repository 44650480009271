import styled from 'styled-components';
import { Transition, theme } from '../../../../styles/constants';

export const StyledOtpInput = styled.input`
  font-size: 1.5rem;
  letter-spacing: -0.15px;
  line-height: normal;
  font-weight: 400;
  color: ${theme.colors.textColor};
  background: ${theme.colors.white};
  height: 4.8rem;
  width: 4.8rem;
  border: 1px solid #97c9f1;
  border-radius: 12px;
  -webkit-transition: ${Transition.hover.off};
  -moz-transition: ${Transition.hover.off};
  -ms-transition: ${Transition.hover.off};
  -o-transition: ${Transition.hover.off};
  transition: ${Transition.hover.off};
  box-shadow: none;
  outline: none;
  text-align: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};

  ::placeholder {
    color: #0b3a5f;
    font-weight: 300;
  }

  @media only screen and (max-width: 380px) {
    width: 5rem;
  }

  @media only screen and (max-width: 350px) {
    width: 4.8rem;
  }
`;

export const OtpInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
