import styled from 'styled-components';
import { Text16Light, Text16Regular, Text24Bold } from '../../../components/utils/Text/Text.styles';
import { theme } from '../../../styles/constants';

export const PageContainer = styled.div`
  max-height: 100dvh;
  height: 100%;
  display: flex;

  @media only screen and (max-width: 768px) {
    max-height: calc(100dvh - 5.6rem);
  }
`;

export const Title = styled(Text24Bold)`
  letter-spacing: -0.408px;
  margin-left: 1.6rem;
`;

export const Navigator = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.4rem 0;
  min-width: 38rem;
  border-right: 1px solid #f3f4f6;
  background-color: #fff;

  @media screen and (max-width: 1100px) {
    max-width: 55rem;
  }

  @media screen and (max-width: 900px) {
    max-width: 25rem;
    min-width: 25rem;
  }

  @media screen and (max-width: 768px) {
    min-width: unset;
    max-width: unset;
    width: 100%;
    border-right: none;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled(Text16Light)`
  opacity: 0.5;
  margin-bottom: 0.8rem;
  margin-left: 1.6rem;
`;

export const NavigatorContent = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 2.8rem;
`;

export const NavigatorItem = styled.li`
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.6rem;

  a {
    color: ${theme.colors.textColor};
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
    height: 5.2rem;
    padding: 2.4rem 1.6rem 2.4rem 2.4rem;

    svg {
      margin-right: 1.3rem;
    }
  }

  a.active {
    position: relative;
    background: ${({ $smallScreen }) => !$smallScreen && '#F9FAFB'};
  }

  a.active:before {
    content: '';
    position: absolute;
    z-index: 1;
    inset: 0;
    padding: 1px;
    border-radius: 10px;
  }
`;

export const NavLinkText = styled(Text16Regular)``;

export const Content = styled.div`
  overflow: scroll;
  width: 100%;
  height: 100%;
`;
