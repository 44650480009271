import React from 'react';
import {
  BottomContainer,
  BottomText,
  Card,
  GrossValue,
  HiddenGrossValue,
  PrefixText,
  SuffixText,
  TitleContainer,
  ValueContainer,
  ValueTextContainer,
} from './StatisticCard.styles';
import { Text16Light, Text24Bold } from '../../utils/Text/Text.styles';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up-right.svg';
import { formatPrice } from '../../../utils/util';

const StatisticCard = ({ title, icon, valuePrefix, valueSuffix, value, bottomValue, hideGross, disableFormatting }) => {
  return (
    <Card>
      <TitleContainer>
        <Text16Light>{title}</Text16Light>
        {icon}
      </TitleContainer>
      <ValueContainer>
        {hideGross ? <HiddenGrossValue>0</HiddenGrossValue> : <GrossValue>${formatPrice(value)} Net</GrossValue>}
        <ValueTextContainer>
          {valuePrefix && <PrefixText>{valuePrefix}</PrefixText>}
          <Text24Bold>{disableFormatting ? value : `$${formatPrice(value * (hideGross ? 1 : 2))}`}</Text24Bold>
          {valueSuffix && <SuffixText>{valueSuffix}</SuffixText>}
        </ValueTextContainer>
        {bottomValue && (
          <BottomContainer>
            <BottomText>{`+${bottomValue} this week`}</BottomText>
            <ArrowUpIcon />
          </BottomContainer>
        )}
      </ValueContainer>
    </Card>
  );
};

export default StatisticCard;
