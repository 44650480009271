import styled from 'styled-components';
import { Text14Light, Text14Regular } from '../../utils/Text/Text.styles';
import { theme } from '../../../styles/constants';

export const Button = styled.button`
  border-radius: 12px;
  border: 1px solid #61ade9;
  background: linear-gradient(180deg, #1a88df 0%, #0b3a5f 100%);
  padding: 0.8rem 1.6rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  &:disabled {
    background: #f5f5f5;
    border: none;

    svg {
      cursor: pointer;
    }
  }
`;

export const Title = styled(Text14Regular)`
  letter-spacing: -0.42px;
  color: ${({ $disabled }) => ($disabled ? '#1672BA' : theme.colors.white)};
`;

export const ButtonContainer = styled.div`
  position: relative;
`;
export const TooltipContainer = styled.div`
  border-radius: 12px;
  border: 1px solid #badaf7;
  background: #d8eaf9;
  padding: 1.2rem;
  position: absolute;
  width: 24rem;
  right: 0;
  top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  z-index: 1000;
`;

export const TooltipText = styled(Text14Light)`
  opacity: 0.8;
  line-height: 20px;
  letter-spacing: -0.408px;
`;
