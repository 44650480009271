import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${({ $hideNavigation }) => ($hideNavigation ? '100%' : '144rem')};
  display: flex;
  margin-inline: auto;
  border-right: ${({ $hideNavigation }) => ($hideNavigation ? 'none' : '1px solid #EEECFB')};
  min-height: 100%;

  @media only screen and (max-width: 768px) {
    padding-bottom: 5.6rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  background: #fcfcfd;
  flex-direction: column;
  width: ${({ $hideNavigation }) => ($hideNavigation ? '100%' : 'calc(100% - 22rem)')};
  margin-left: ${({ $hideNavigation }) => ($hideNavigation ? '0' : '22rem')};

  @media only screen and (max-width: 1440px) {
    border-right: none;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: unset;
  }
`;
