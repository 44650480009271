import React, { useMemo } from 'react';
import { Container } from './AgencyHomePieChart.styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { EARNINGS_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../utils/constants';
import { formatPrice } from '../../utils/util';
import { useSelector } from 'react-redux';
import { selectEarningType } from '../../store/slices/user/slice';

const AgencyHomePieChart = ({ chartData }) => {
  const earningType = useSelector(selectEarningType);

  const data = useMemo(() => {
    const influencers = Object.keys(chartData);
    const colors = [
      { color1: '#EEF5FD', color2: '#EEF5FD' },
      { color1: '#D8EAF9', color2: '#D8EAF9' },
      { color1: '#BADAF7', color2: '#BADAF7' },
      { color1: '#97C9F1', color2: '#97C9F1' },
      { color1: '#61ADE9', color2: '#61ADE9' },
      { color1: '#3E9BE4', color2: '#3E9BE4' },
      { color1: '#1A88DF', color2: '#1A88DF' },
      { color1: '#1672BA', color2: '#1672BA' },
      { color1: '#0B3A5F', color2: '#0B3A5F' },
      { color1: '#092F4D', color2: '#092F4D' },
      { color1: '#051928', color2: '#051928' },
    ];

    const multiplier = earningType.value === EARNINGS_TYPE.NET ? 1 : 2;

    return influencers.map((influencer, index) => {
      return {
        name: influencer,
        y: chartData[influencer] * VALUE_OF_ONE_STAR_IN_USD * multiplier,
        ...(colors[index] && {
          color: {
            linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
            stops: [
              [0, colors[index].color1],
              [1, colors[index].color2],
            ],
          },
        }),
      };
    });
  }, [chartData, earningType]);

  function renderSubtitle() {
    const totalNumber = formatPrice(data.reduce((acc, curr) => acc + curr.y, 0));
    return `<span style="font-size: 10px; color: #150C2A; opacity: 0.5; margin-inline:auto">TOTAL</span><br/>
            <span style="font-size: 12px; font-weight: 600; color: #150C2A; opacity: unset;">$${totalNumber}</span>
        `;
  }

  const options = {
    chart: {
      type: 'pie',
      style: {
        fontFamily: 'Lexend',
      },
    },
    title: {
      text: '',
    },
    subtitle: {
      useHTML: true,
      text: renderSubtitle(),
      floating: true,
      verticalAlign: 'middle',
      style: {
        textAlign: 'center',
      },
    },
    credits: {
      enabled: false,
    },

    plotOptions: {
      pie: {
        borderWidth: 0,
        borderRadius: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    accessibility: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: 'transparent',
      borderWidth: 0,
      shadow: false,
      padding: 0,
      formatter: function () {
        return `
            <div style="
            display: flex;
            align-items: center;
            padding: 4px 12px;
            border-radius: 24px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%), #FFF;
            box-shadow: 0px 2px 2px 0px rgba(21, 12, 42, 0.07);
            border: 1px solid #CBC0EE;
            color: #150C2A;
            font-size: 12px;
            font-weight: 500;
            ">
            <span style="margin-right: auto;">${this.point.name}</span>
            <span style="margin-left: 8px;">$${formatPrice(this.y)}</span>
            </div>
        `;
      },
      style: {
        pointerEvents: 'none',
      },
    },
    series: [
      {
        animation: {
          duration: 2000,
        },
        colorByPoint: true,
        innerSize: '65%',
        data: data,
      },
    ],
  };
  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
};

export default AgencyHomePieChart;
