import styled from 'styled-components';
import { Text13Light, Text13Regular } from '../utils/Text/Text.styles';
import { theme } from '../../styles/constants';

export const Container = styled.div`
  display: flex;
  gap: 4.4rem;

  @media only screen and (max-width: 950px) {
    gap: 2rem;
  }

  @media only screen and (max-width: 890px) {
    gap: 4.4rem;
  }

  @media only screen and (max-width: 700px) {
    gap: 2rem;
  }

  @media only screen and (max-width: 630px) {
    gap: 1.2rem;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const StepCircle = styled.div`
  border-radius: 50%;
  background: ${({ $active, $finished }) => ($finished ? '#1672BA' : $active ? '#fff' : '#DEDBF9')};
  border: 1px solid ${({ $active, $finished }) => ($finished ? '#1672BA' : $active ? '#A377FF' : '#C5BEF4')};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CircleText = styled(Text13Regular)`
  opacity: ${({ $active }) => ($active ? '1' : '0.8')};
  color: #1672ba;
`;

export const StepText = styled(Text13Light)`
  color: ${theme.colors.textColor};
  font-weight: ${({ $active }) => $active && '500'};
  letter-spacing: -0.408px;
  text-align: center;
  display: ${({ $finished }) => $finished && 'none'};

  @media only screen and (max-width: 890px) {
    display: ${({ $active }) => !$active && 'none'};
  }
`;
