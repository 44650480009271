import styled, { css } from 'styled-components';

export const AccordionContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const AccordionItem = styled.div`
  overflow: hidden;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
  background-color: ${({ $isOpen }) => $isOpen && '#F9FAFB'};

  &:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

export const AccordionHeader = styled.div`
  padding: 1.2rem 3.6rem 1.2rem 1.2rem;
  cursor: pointer;
  display: flex;

  align-items: center;
  position: relative;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
  }
`;

export const AccordionContent = styled.div`
  overflow: hidden;
  transition: all 0.3s ease;

  height: 0;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      height: ${({ $contentHeight }) => `${$contentHeight / 10}rem`};
      padding: 1.2rem;
    `}
`;

export const Icon = styled.span`
  transform: rotate(-90deg);
  position: absolute;
  right: 1.6rem;

  transition: transform 0.3s ease;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(90deg);
    `}
`;

export const RightSideContent = styled.div`
  gap: 1.4rem;
  align-items: center;
  width: 100%;
`;

export const TitleContainer = styled.div`
  min-width: 14rem;
`;
