import styled from 'styled-components';
import { Text15Bold } from '../../../components/utils/Text/Text.styles';
import { Button } from '../../../components/utils/Button/Button';

export const OtpContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media only screen and (min-width: 500px) {
    flex: unset;
  }
`;

export const ResendCodeContainer = styled.div`
  display: flex;
  gap: 0.4rem;
`;

export const ResendText = styled(Text15Bold)`
  color: #1672ba;
  text-decoration: underline;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ $disabled }) => ($disabled ? 0.3 : 1)};
  margin-top: 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TimeRemainText = styled(Text15Bold)`
  color: #1672ba;
`;

export const StyledButton = styled(Button)`
  margin-top: 10.5rem;

  @media only screen and (max-width: 500px) {
    margin-top: auto;
    margin-bottom: 6.5rem;
  }
`;
