import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 2.4rem 1.6rem;
  gap: 0.6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
  flex-grow: 1;
`;

export const StatisticContainer = styled.div`
  display: flex;
  gap: 1.6rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ChartContainer = styled.div`
  border-radius: 16px;
  border: 1px solid #f3f4f6;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    height: unset;
  }
`;

export const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 6rem;
  padding: 1rem 1.6rem 1rem 2rem;
  border-bottom: 1px solid #f3f4f6;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const Line = styled.div`
  width: 1px;
  background: #f3f4f6;
  height: 100%;

  @media only screen and (max-width: 600px) {
    height: 1px;
    width: 100%;
  }
`;

export const ChartContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .recharts-default-legend {
    display: flex;
    flex-direction: row-reverse;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const AnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalContainer = styled.div`
  padding: 1.6rem 1.2rem;
  display: flex;
  justify-content: space-between;
  min-height: 7rem;
`;

export const TitleRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 768px) {
    gap: 1rem;
  }
`;
