import React, { useState } from 'react';
import { Button, OnboardingContainer } from '../../../components';
import {
  AgreementContent,
  AgreementText,
  BottomContainer,
  CheckboxContainer,
  CheckboxText,
  CheckboxWrapper,
  Content,
} from './AcceptAgreementPage.styles';
import { Text20Bold } from '../../../components/utils/Text/Text.styles';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as CheckEmptyIcon } from '../../../assets/icons/check-empty.svg';
import { useDispatch, useSelector } from 'react-redux';
import { acceptAgreement } from '../../../store/slices/influencerRequest/asyncThunks';
import { selectInfluencerRequestActionsPending } from '../../../store/slices/influencerRequest/slice';
import { notifyError } from '../../../utils/notify';
import { setUserStatus } from '../../../store/slices/user/slice';
import { INFLUENCER_REQUEST_STATUS } from '../../../utils/constants';

const AcceptAgreementPage = () => {
  const dispatch = useDispatch();
  const { acceptAgreement: acceptAgreementPending } = useSelector(selectInfluencerRequestActionsPending);
  const [acceptedAgreement, setAcceptedAgreement] = useState(false);

  const onSubmit = () => {
    dispatch(acceptAgreement())
      .unwrap()
      .then(() => {
        dispatch(setUserStatus(INFLUENCER_REQUEST_STATUS.COMPLETED));
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <OnboardingContainer>
      <Content>
        <Text20Bold>Agreement</Text20Bold>
        <AgreementContent>
          <AgreementText>
            This Agreement ("Agreement") is made and entered into as of this [Date], by and between [Your Company Name],
            with its principal place of business at [Your Company Address] ("Company"), and [Individual's Name],
            residing at [Individual's Address] ("Individual"). 1. Grant of Rights Individual hereby grants Company the
            right to use their name, likeness, image, voice, and biographical information (collectively, "Likeness") in
            any media, whether now known or hereafter devised, throughout the world in perpetuity, for the purpose of
            promoting, advertising, and marketing the Individual's profile on [Platform/App Name]. 2. Use of Media
            Company may create, reproduce, distribute, publicly display, and perform, and otherwise use the Likeness in
            promotional materials, advertisements, social media, and other marketing collateral. This includes but is
            not limited to, digital, print, and broadcast media. 3. No Obligation Company is not obligated to use the
            Likeness in any specific way or at all. 4. Compensation In consideration for the rights granted herein,
            Individual will receive [describe compensation, e.g., a one-time payment of $[Amount], or a percentage of
            revenue generated from the promotion, etc.]. No other compensation shall be due to Individual. 5.
            Representations and Warranties Individual represents and warrants that they have the full right, power, and
            authority to grant the rights granted herein and that the use of their Likeness by Company as permitted by
            this Agreement will not violate any rights of any third party. 6. Indemnification Individual agrees to
            indemnify and hold harmless Company and its directors, officers, employees, and agents from and against any
            claims, damages, liabilities, costs, and expenses arising out of or related to any breach of the
            representations and warranties set forth in this Agreement. 7. Governing Law This Agreement shall be
            governed by and construed in accordance with the laws of the State of [Your State], without regard to its
            conflicts of law principles. 8. Entire Agreement This Agreement constitutes the entire agreement between the
            parties with respect to its subject matter and supersedes all prior or contemporaneous understandings or
            agreements, whether written or oral, regarding such subject matter. 9. Amendments This Agreement may not be
            amended or modified except in writing signed by both parties. 10. Severability If any provision of this
            Agreement is held to be invalid or unenforceable, the remaining provisions shall continue in full force and
            effect. IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the date first above
            written.
          </AgreementText>
        </AgreementContent>
        <BottomContainer>
          <CheckboxContainer>
            <CheckboxWrapper
              onClick={() => {
                setAcceptedAgreement(!acceptedAgreement);
              }}>
              {acceptedAgreement ? <CheckIcon color="black" /> : <CheckEmptyIcon color="black" />}
            </CheckboxWrapper>
            <CheckboxText>I agree to TLINK.ME using my media, name and likeness to promote my profile.</CheckboxText>
          </CheckboxContainer>
          <Button title="Submit" disabled={!acceptedAgreement} onClick={onSubmit} isLoading={acceptAgreementPending} />
        </BottomContainer>
      </Content>
    </OnboardingContainer>
  );
};

export default AcceptAgreementPage;
