import styled from 'styled-components';
import { Text14Light, Text14Regular } from '../utils/Text/Text.styles';

export const Card = styled.div`
  border-radius: 12px;
  border: 1px solid #97c9f1;
  background: #fff;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  align-items: center;

  padding: ${({ $selected }) => ($selected ? '1.2rem' : '1.2rem 1.2rem 0 1.2rem')};
  background-color: ${({ $selected }) => ($selected ? '#F3F4F6' : 'transparent')};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3.6rem;
  padding: ${({ $selected }) => ($selected ? '1.2rem' : '0.6rem 1.2rem 1.2rem 1.2rem')};
  gap: 0.4rem;
`;

export const Text = styled(Text14Light)`
  letter-spacing: -0.408px;
  opacity: 0.6;
`;

export const AddNewButton = styled.button`
  border-radius: 12px;
  border: 1px solid #97c9f1;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  outline: none;
  cursor: pointer;
  height: 4.8rem;
  background-color: #fff;
  margin-top: 1.2rem;
`;

export const ButtonText = styled(Text14Regular)`
  color: #1672ba;
`;

export const MethodCard = styled.div`
  min-height: 6.6rem;
  border-radius: 12px;
  border: 1px solid #97c9f1;
  background: #fff;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  padding: 1.2rem;
  cursor: pointer;
`;

export const MethodsContainer = styled.div``;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;
