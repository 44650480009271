import styled from 'styled-components';
import { Text14Light, Text15Bold, Text26ExtraBold } from '../../../components/utils/Text/Text.styles';
import { Button } from '../../../components/utils/Button/Button';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 42rem;
  padding-top: 4rem;

  @media only screen and (max-width: 500px) {
    height: 100%;
    padding: 2rem 1.6rem 3rem 1.6rem;
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @media only screen and (max-width: 500px) {
    margin-inline: unset;
  }
`;

export const Title = styled(Text26ExtraBold)`
  text-align: center;
  margin-inline: 1.3rem;

  @media only screen and (max-width: 500px) {
    margin-inline: 2.4rem;
  }
`;

export const Subtitle = styled(Text14Light)`
  line-height: 2.2rem;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  align-items: center;
  width: 100%;
  margin-top: 3.6rem;

  @media only screen and (max-width: 500px) {
    margin-top: auto;
  }
`;

export const StyledButton = styled(Button)`
  width: 28rem;
  margin-top: auto;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const HaveCodeButton = styled(Text15Bold)`
  text-decoration: underline;
  cursor: pointer;
  color: #1672ba;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  flex: 1;
  width: 100%;
`;

export const Image = styled.img`
  margin-bottom: 3.2rem;
  max-width: 58rem;
  width: 100%;
  height: 14rem;
`;
