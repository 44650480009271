import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { Content, PageContainer, StatisticContainer, TitleContainer } from './WalletPage.styles';
import { Text24Bold } from '../../components/utils/Text/Text.styles';
import { RequestWithdrawalButton, StatisticCard, Table, WithdrawalItem } from '../../components';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet.svg';
import { ReactComponent as SafeIcon } from '../../assets/icons/safe.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card-down.svg';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../utils/constants';
import { selectFetchWithdrawalsInfo } from '../../store/slices/withdrawal/slice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchWithdrawals } from '../../store/slices/withdrawal/asyncThunks';
import { WITHDRAW } from '../../components/App/routes';

const WalletPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;

  const user = useSelector(selectUser);
  const { data, total, pending } = useSelector(selectFetchWithdrawalsInfo);
  const { earnings } = user.analytics;

  const dispatch = useDispatch();

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchWithdrawals({ page }));
    }
  }, [dispatch, page]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  const tableHeader = [{ label: 'Created At' }, { label: 'Amount' }, { label: 'Status' }];

  return (
    <PageContainer>
      <TitleContainer>
        <Text24Bold>Wallet</Text24Bold>
        <RequestWithdrawalButton
          disabled={
            earnings.available * VALUE_OF_ONE_STAR_IN_USD < 500 ||
            user.thisMonthWithdrawals >= 2 ||
            user.hasPendingWithdrawal
          }
          title="Request Withdrawal"
          onClick={() => navigate(`/${WITHDRAW}`)}
        />
      </TitleContainer>
      <Content>
        <StatisticContainer>
          <StatisticCard
            title="Available Balance"
            icon={<CreditCardIcon />}
            value={earnings.available * VALUE_OF_ONE_STAR_IN_USD}
            hideGross
          />
          <StatisticCard
            title="Pending Balance"
            icon={<WalletIcon />}
            value={(earnings.balance - earnings.available) * VALUE_OF_ONE_STAR_IN_USD}
            hideGross
          />
          <StatisticCard
            title="Lifetime Proceeds"
            icon={<SafeIcon />}
            value={earnings.lifetime * VALUE_OF_ONE_STAR_IN_USD}
          />
        </StatisticContainer>
        <Table
          headerInformation={tableHeader}
          dataLoading={pending}
          totalCount={total}
          currentPage={page}
          perPage={10}
          setPage={setPage}
          emptyDataText={'No data'}
          fullHeight>
          {data.map(withdrawal => (
            <WithdrawalItem
              key={withdrawal.id}
              status={withdrawal.status}
              amount={withdrawal.amount}
              createdAt={withdrawal.createdAt}
            />
          ))}
        </Table>
      </Content>
    </PageContainer>
  );
};

export default WalletPage;
