import React from 'react';
import { Card, CardContent, CategoryText } from './AnalyticsCard.styles';
import { Text18Regular } from '../../utils/Text/Text.styles';
import { EARNINGS_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';
import { formatPrice } from '../../../utils/util';
import { useSelector } from 'react-redux';
import { selectEarningType } from '../../../store/slices/user/slice';

const AnalyticsCard = ({ earnings, icon, title }) => {
  const earningType = useSelector(selectEarningType);
  const multiplier = earningType.value === EARNINGS_TYPE.NET ? 1 : 2;

  return (
    <Card>
      {icon}
      <CardContent>
        <CategoryText>{title}</CategoryText>
        <Text18Regular>${formatPrice(earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier)}</Text18Regular>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCard;
