import styled from 'styled-components';
import { Text12Light, Text16Bold } from '../../Text/Text.styles';

export const Container = styled.div``;

export const UploadFileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 16px;
  background: #f3f4f6;
  height: 12.6rem;
  padding: ${({ $hasValue }) => ($hasValue ? '1.2rem' : '2.4rem 0 1.2rem 0')};
`;

export const UploadFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
`;

export const Title = styled(Text16Bold)`
  color: #1672ba;
  text-decoration-line: underline;
`;

export const Text = styled(Text12Light)`
  opacity: 0.2;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const CloseIconWrapper = styled.div`
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(70, 40, 135, 0.5);
  position: absolute;
  right: 0;
`;
