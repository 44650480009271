import styled from 'styled-components';
import { Text13Light, Text14Light } from '../utils/Text/Text.styles';

export const EarningsByMonthContainer = styled.div`
  border-radius: 16px;
  border: 1px solid #f3f4f6;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
`;

export const AccordionItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  height: 100%;
`;

export const AccordionItemFooter = styled.div`
  display: flex;
  padding-right: 1.2rem;

  @media only screen and (max-width: 768px) {
    gap: 2rem;
  }
`;

export const FooterTextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    width: unset;
  }
`;

export const FooterText = styled(Text14Light)`
  opacity: 0.78;
  margin-right: 0.4rem;
`;

export const DollarSign = styled(Text13Light)`
  opacity: 0.3;
  margin-bottom: 0.4rem;
  margin-right: 0.2rem;
`;

export const ItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  overflow: auto;
  height: 100%;
`;

export const ItemCard = styled.div`
  border-radius: 1.2rem;
  border: 1px solid #f3f4f6;
  background: #fff;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const Avatar = styled.img`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  border: 1px solid rgba(159, 135, 253, 0.16);
`;

export const LeftContent = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  min-width: 14rem;
`;

export const RightContent = styled.div`
  display: flex;
  width: 100%;
  gap: 1.2rem;
`;

export const LeftContentEmpty = styled.div`
  min-width: 14rem;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
