import styled from 'styled-components';
import { Text14Light } from '../../utils/Text/Text.styles';

export const Button = styled.button`
  border-radius: 12px;
  border: 1px solid ${({ $selected }) => ($selected ? '#61ADE9' : '#97C9F1')};
  background: ${({ $selected }) => ($selected ? '#3E9BE4' : '#D8EAF9')};
  padding: 0.6rem 1.2rem;
  cursor: pointer;
`;

export const Title = styled(Text14Light)`
  line-height: 22px;
  letter-spacing: -0.408px;
  color: ${({ $selected }) => ($selected ? '#fff' : '#1672BA')};
`;
