import styled from 'styled-components';
import { Text14Bold } from '../utils/Text/Text.styles';
import { theme } from '../../styles/constants';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${({ $hasHeader }) => $hasHeader && '13rem'};
  background: ${theme.colors.white};
  position: relative;
  @media only screen and (max-height: 880px) {
    padding-top: ${({ $hasHeader }) => $hasHeader && '6.6rem'};
    align-items: center;
  }

  @media only screen and (max-width: 500px) {
    padding-top: ${({ $hasHeader }) => $hasHeader && '6.6rem'};
    align-items: flex-start;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const BackContainer = styled.div`
  position: absolute;
  top: 0rem;
  left: 0.7rem;
  cursor: pointer;
  width: 3.2rem !important;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoutContainer = styled.div`
  border-radius: 12px;
  padding: 1.4rem 0 1.4rem 1.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.4rem;
  width: 9.9rem;
  height: 4rem;
  cursor: pointer;
  margin-left: 5.6rem;

  @media only screen and (max-width: 600px) {
    background: transparent;
    border: none;
    border-radius: 0;
    width: 4rem;
    height: 4rem;
    padding: 0;
    margin-left: unset;
  }
`;

export const LogoutText = styled(Text14Bold)`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const Header = styled.div`
  height: 6.6rem;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1.8rem;
  border-bottom: 1px solid #eeecfb;
  background-color: #f9fafb;
`;
