import React, { useEffect, useState } from 'react';
import { Container, Content, Header, HeaderRow, HeaderText, NumOfMediaText, StyledButton } from './UploadVideos.styles';
import { Text18Bold } from '../utils/Text/Text.styles';
import { MIN_NUM_OF_VIDEOS, UPLOAD_FILE_CONSTRAINTS } from '../../utils/constants';
import { notifyError, notifyInfo } from '../../utils/notify';
import { useDispatch } from 'react-redux';
import UploadMediaButton from '../UploadMediaButton/UploadMediaButton';
import MediaComponent from '../MediaComponent/MediaComponent';
import { updateNumOfUpdatedMedia } from '../../store/slices/user/slice';
import { uploadVideo } from '../../store/slices/influencerRequest/asyncThunks';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-01.svg';

const UploadVideos = () => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const [errors, setErrors] = useState([]);
  const [uploadingProgress, setUploadingProgress] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (uploadingProgress.length && !uploadingProgress.filter(progress => progress !== 100).length) {
      dispatch(
        updateNumOfUpdatedMedia({ numOfMedia: uploadingProgress.length, type: UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO }),
      );
      setLoading(false);
      notifyInfo('Videos uploaded successfully');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingProgress]);

  const myUploadProgress = index => progress => {
    let percentage = Math.floor((progress.loaded * 100) / progress.total);
    setUploadingProgress(prev => {
      const newProgress = [...prev];
      newProgress[index] = percentage > 99 ? 99 : percentage;
      return newProgress;
    });
  };

  const onUploadClick = () => {
    setLoading(true);
    for (const [index, video] of videos.entries()) {
      dispatch(
        uploadVideo({
          video,
          onUploadProgress: myUploadProgress(index),
        }),
      )
        .unwrap()
        .then(() => {
          setUploadingProgress(prev => {
            const newProgress = [...prev];
            newProgress[index] = 100;
            return newProgress;
          });
        })
        .catch(err => {
          setLoading(false);
          notifyError(err.message);
        });
    }
  };

  const onVideoDelete = (index, fileName) => {
    setVideos(prev => prev.filter((_, ind) => index !== ind));
    setErrors(prev => prev.filter(error => error.fileName !== fileName));
  };

  return (
    <Container>
      <Header>
        <HeaderRow>
          <Text18Bold>Videos</Text18Bold>
          {videos.length < MIN_NUM_OF_VIDEOS ? (
            <NumOfMediaText>
              {videos.length}/{MIN_NUM_OF_VIDEOS}
            </NumOfMediaText>
          ) : (
            <CheckIcon stroke="#1672BA" />
          )}
        </HeaderRow>
        <HeaderText>Upload at least {MIN_NUM_OF_VIDEOS} videos. </HeaderText>
      </Header>
      <Content>
        <UploadMediaButton
          numOfUploadedMedia={videos.length}
          disabled={loading}
          type={UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO}
          onChange={files => setVideos([...videos, ...files])}
          setError={setErrors}
        />
        {videos.map((video, index) => {
          return (
            <MediaComponent
              key={video.file.name}
              type={UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO}
              file={video}
              progress={uploadingProgress[index]}
              onDelete={() => onVideoDelete(index, video.file.name)}
              error={errors.find(error => error.fileName === video.file.name)}
            />
          );
        })}
      </Content>
      <StyledButton
        title="Upload"
        onClick={onUploadClick}
        isLoading={loading}
        disabled={videos.length < MIN_NUM_OF_VIDEOS || !!errors.length}
      />
    </Container>
  );
};

export default UploadVideos;
