import styled from 'styled-components';

export const Container = styled.div``;

export const UploadFileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const LogoWrapper = styled.div`
  width: 12rem;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #f5f5f5;
  border-radius: 50%;
  overflow: hidden;

  svg {
    margin-left: 0.5rem;
  }
`;

export const UploadFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const UploadButton = styled.div`
  border-radius: 12px;
  border: 1px solid #61ade9;
  background: linear-gradient(180deg, #1a88df 0%, #0b3a5f 100%);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.42px;
  color: #fff;
  padding: 0.8rem 1.6rem;
  margin-top: -1.2rem;
  z-index: 1;
  cursor: pointer;
`;
