import styled from 'styled-components';
import { Text14Light, Text20Bold } from '../utils/Text/Text.styles';

export const UploadFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ButtonContainer = styled.div`
  width: ${({ $noMediaUploaded }) => ($noMediaUploaded ? '100%' : '11.5rem')};
  height: ${({ $noMediaUploaded }) => ($noMediaUploaded ? '28.4rem' : '11.5rem')};
  background: ${({ $noMediaUploaded }) =>
    $noMediaUploaded ? 'transparent' : 'linear-gradient(180deg, #4F2D97 0%, #371F6B 100%)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
  border-radius: 8px;
`;

export const UploadText = styled(Text20Bold)`
  text-decoration: underline;
  font-size: ${({ $noMediaUploaded }) => !$noMediaUploaded && '1.5rem'};
  color: ${({ $noMediaUploaded }) => ($noMediaUploaded ? '#1672BA' : '#fff')};
  font-weight: ${({ $noMediaUploaded }) => !$noMediaUploaded && '400'};
`;

export const Text = styled(Text14Light)`
  opacity: 0.56;
`;
