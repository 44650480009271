export const Transition = {
  hover: {
    on: 'all 0.2s ease-in',
    off: 'all 0.2s ease-out',
  },
  reaction: {
    on: 'all 0.15s ease-in',
    off: 'all 0.1s ease-out',
  },
  dropdown: {
    off: 'all 0.35s ease-out',
  },
};

export const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    error: '#FF2226',
    textColor: '#040B10',
  },
};
